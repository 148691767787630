import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import changeIntakeInfo from '../../actions/changeIntakeInfo';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_INTAKE from '../../gql/mutation/updateIntake';
import { TextField, Button } from '@material-ui/core';
import RadioSelector from '../../Components/Selector/RadioSelector';
import SelectorWithBorder from '../../Components/Selector/SelectorWithBorder';
import InfantSelector from '../../Components/Selector/InfantSelector';
import BooleanSelector from '../../Components/Selector/BooleanSelector';
import Step from '../../Components/Step/Step';
import Loading from '../../Components/Lottie/Loading';
import Error from '../../Components/Error/Error';

const LASTMASSAGE = ['one week', 'two weeks', '1 month', '2 months', '3 months', '6 months', '1 year', '1 year+'];
const DELIVERY = ['vaginal forceps', 'cesarean', 'vacuum extraction', 'vaginal'];

const Infant = ({ intake, changeIntakeInfo, user, infant }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [updateIntake, updateIntakeRes] = useMutation(UPDATE_INTAKE);


  const stateToString = (state) => {
    const stateArr = Object.keys(state).filter(key => state[key]);
    return stateArr.join(' | ');
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);
      const intakeForm = { 
        ...intake,
        otherConditions: stateToString(infant),
      };

      delete intakeForm.isPhysioOnly;

      const res = await updateIntake({
        variables: {
          intake: intakeForm,
        }
      });

      setLoading(false);
      if (res.data.updateIntake.success) {
        history.push('/step-3');
      } else {
        setError(res.data.updateIntake.message);
      }
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return (
    <div className='page'>
      {loading && <Loading />}
      <form className='panel-body flex-center' onSubmit={handleSubmit}>
        <Step step={2} />
        <TextField required value={intake.weeksGestation} onChange={(e) => changeIntakeInfo('weeksGestation', e.target.value)} label="Gestational age at birth" variant="outlined" />
        <RadioSelector options={DELIVERY} label={`How was baby ${user.firstName} delivered?`} selectedValue={intake.deliveryMethod} onChange={changeIntakeInfo} onChangeKey='deliveryMethod'/>
        <BooleanSelector label='Check this box if there are any challenges during pregnancy for mom and/or baby.' selectedValue={intake.hasBirthComplications} onChange={changeIntakeInfo} onChangeKey='hasBirthComplications' />
        {intake.hasBirthComplications && <TextField multiline rows="2" value={intake.birthComplications} onChange={(e) => changeIntakeInfo('birthComplications', e.target.value)} label="Please describe complications in detail!" variant="outlined" />}
        <BooleanSelector label='Check this box if baby is given any medication.' selectedValue={intake.hasMedication} onChange={changeIntakeInfo} onChangeKey='hasMedication' />
        {intake.hasMedication && <TextField multiline rows="2" value={intake.medication} onChange={(e) => changeIntakeInfo('medication', e.target.value)} label="What medications are being given and why?" variant="outlined" />}
        <BooleanSelector label='Check this box if there are any postpartum complication for baby.' selectedValue={intake.hasPostpartumComplication} onChange={changeIntakeInfo} onChangeKey='hasPostpartumComplication' />
        {intake.hasPostpartumComplication && <TextField multiline rows="2" value={intake.postpartumComplication} onChange={(e) => changeIntakeInfo('postpartumComplication', e.target.value)} label="Please explain your pain in detail, including what makes it better and what makes it worse." variant="outlined" />}
        <BooleanSelector label='Check this box if baby had any surgical procedure or injury.' selectedValue={intake.hasSurgeryOrInjury} onChange={changeIntakeInfo} onChangeKey='hasSurgeryOrInjury'/>
        {intake.hasSurgeryOrInjury && <TextField multiline rows="2" value={intake.surgeryOrInjury} onChange={(e) => changeIntakeInfo('surgeryOrInjury', e.target.value)} label="Please explain" variant="outlined" />}
        <BooleanSelector ariaLabel='allergies or sensitivities' label='Check this box if baby have any allergies or sensitivities.' selectedValue={intake.hasAllergies} onChange={changeIntakeInfo} onChangeKey='hasAllergies' />
        {intake.hasAllergies && <TextField multiline rows="2" value={intake.allergies} onChange={(e) => changeIntakeInfo('allergies', e.target.value)} label="Please explain" variant="outlined" />}
        <BooleanSelector label='Check this box if baby had Registered Massage Therapy before.' selectedValue={intake.hadMassageBefore} onChange={changeIntakeInfo} onChangeKey='hadMassageBefore'/>
        {intake.hadMassageBefore && <SelectorWithBorder ariaLabel='last massage date' selectedValue={intake.lastMassage} onChange={changeIntakeInfo} onChangeKey='lastMassage' label='When was your last massage?' options={LASTMASSAGE} />}
        <InfantSelector />
        <TextField multiline rows="2" value={intake.conditionNotes} onChange={(e) => changeIntakeInfo('conditionNotes', e.target.value)} label="Please explain in detail regarding any of the above checked health conditions" variant="outlined" />
        <TextField multiline rows="2" value={intake.extraNotes} onChange={(e) => changeIntakeInfo('extraNotes', e.target.value)} label={`Is there anything else about baby ${user.firstName} you would like us to know? (ie delivery, development, and etc)`} variant="outlined" />
        {error && <Error error={error} />}
        <div className='margin-top-20'>
          <Button type="submit" color="secondary" variant="contained">
            Save and Continue
          </Button>
        </div>
      </form>
    </div>
  )
};

const mapStateToProps = (state) => ({
  intake: state.intake,
  user: state.user,
  infant: state.infant,
});

const mapDispatchToProps = dispatch => ({
  changeIntakeInfo: (key, value) => {
    dispatch(changeIntakeInfo(key, value))
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(Infant);

import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import changeIntakeInfo from '../../actions/changeIntakeInfo';

const UserOption = ({ changeIntakeInfo }) => {
  const history = useHistory();

  const handleClick = (userType) => {
    if (userType === 'baby') {
      changeIntakeInfo('userType', userType);
      history.push('/step-2-infant');
    } else {
      changeIntakeInfo('userType', userType);
      history.push('/step-2');
    }
  };

  return (
    <div className='user-option-wrapper'>
      <div className='user-option'>
        <button onClick={() => handleClick('prenatal')} className='button-outline teal'>
          I am Prenatal Mama
        </button>
        <button onClick={() => handleClick('postnatal')} className='button-outline lightGreen'>
          I am a Postnatal Mama
        </button>
        <button onClick={() => handleClick('busymama')} className='button-outline mauve'>
          I am a Busy Mama
        </button>
        <button onClick={() => handleClick('partner')} className='button-outline purple'>
          I am a Mama's Partner
        </button>
        <button onClick={() => handleClick('baby')} className='button-outline peach'>
          I am a Baby
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  changeIntakeInfo: (key, value) => {
    dispatch(changeIntakeInfo(key, value))
  }
});

export default connect (
  null,
  mapDispatchToProps
)(UserOption);

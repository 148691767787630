const initialLoad = ({ user, address, intake, digestive, musculoSkeletal, respiratory, otherConditions, skin, nervousSystem, prenatal, infant }) => {
  return {
    type: 'INITIAL_LOAD',
    user,
    address,
    intake,
    digestive,
    musculoSkeletal,
    respiratory, 
    otherConditions, 
    nervousSystem,
    skin,
    prenatal,
    infant,
  }
}

export default initialLoad;
const initialState = {
  id: '',
  isPhysioOnly: false,
  emergencyContact: '',
  emergencyContactTel: '',
  emergencyContactRel: '',
  familyDoctor: '',
  familyDoctorTel: '',
  familyDoctorAddress: '',
  occupation: '',
  employer: '',
  insurance: '',
  massageFrequency: '',
  idealApptTime: '',
  userType: '',
  hasMedication: false,
  medication: '',
  hasChronicPain: false,
  chronicPain: '',
  hasSurgeryOrInjury: false,
  surgeryOrInjury: '',
  conditionNotes: '',
  hadMassageBefore: false,
  lastMassage: '',
  massageType: '',
  massagePressure: '',
  otherTreatments: '',
  hasAllergies: false,
  allergies: '',
  hasNoMassageArea: false,
  noMassageArea: '',
  hasTreatmentGoal: false,
  treatmentGoal: '',
  extraNotes: '',

  // busy mama & postnatal
  birthDate: '',
  deliveryMethod: '',
  childrenNumber: '',
  hasBirthComplications: false,
  birthComplications: '',
  hasPostpartumDepression: false,
  postpartumDepression: '',

  // prenatal 
  pregnancyNumber: '',
  birthNumber: '',
  trimester: '',
  dueDate: '',
  pregnancyRisk: '',
  hasMidwife: false,
  midwife: '',
  midwifeTel: '',
  prenatalConditionNotes: '',

  // baby 
  weeksGestation: '',
  hasPostpartumComplication: false,
  postpartumComplication: '',
}

const intake = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.intake;
    case 'CHANGE_INTAKE_INFO':
      return {
        ...state,
        [action.key]: action.value,
      }
    default:
      return state;
  }
}

export default intake;
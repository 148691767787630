import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';

const RadioSelector = ({ options, ariaLabel, label, onChange, selectedValue, onChangeKey, displayRow }) => {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup required aria-label={ariaLabel} onChange={(e) => onChange(onChangeKey, e.target.value)} value={selectedValue}>
        <div>
          {options.map(ref => (
            <FormControlLabel key={ref} value={ref} control={<Radio />} label={ref} />
          ))}
        </div>
      </RadioGroup>
    </FormControl>
  )
}

export default RadioSelector;
const ADDRESS = {
  id: '',
  unit: '',
  buzzer: '',
  street: '',
  city: '',
  province: '',
  country: '',
  postalCode: '',
  homeType: '',
  hasStairs: false,
  stairsNotes: '',
  parkingType: '',
  parkingNotes: '',
}

const address = (state = ADDRESS, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.address;
    case 'CHANGE_ADDRESS_INFO':
      return {
        ...state,
        [action.key]: action.value,
      }
    default:
      return state;
  }
}

export default address;
import { gql } from 'apollo-boost';

const UPDATE_INTAKE = gql`
  mutation UpdateIntake(
    $intake: IntakeInput
    $address: AddressInput
    $user: UserInput
  ) {
    updateIntake(
      intake: $intake
      address: $address
      user: $user
    ) {
      success
      message
    }
  }
`;

export default UPDATE_INTAKE;
const OTHER = [
  'loss of appetite', 'depression', 'difficulty concentrating', 'hearing impaired', 'visually impaired',
  'diabetes', 'fibromyalgia', 'post/polio syndrome', 'cancer', 'tuberculosis', 'other health conditions'
];

const initialState = {}; 
OTHER.map(item => initialState[item] = false);

const otherConditions = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.otherConditions;
    case 'CHANGE_OTHER_CONDITIONS':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default otherConditions;
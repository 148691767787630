import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import UPDATE_INTAKE from '../../gql/mutation/updateIntake';
import changeUserInfo from '../../actions/changeUserInfo';
import BooleanSelector from '../../Components/Selector/BooleanSelector';
import Step from '../../Components/Step/Step';
import Error from '../../Components/Error/Error';
import Loading from '../../Components/Lottie/Loading';


const Confirmation = ({ intake }) => {
  const [state, setState] = useState({
    mainFloor: false,
    cancellation: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updateIntake, updateIntakeRes] = useMutation(UPDATE_INTAKE);
  const history = useHistory();

  const handleChange = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });
  };

  const handleConfirmation = async (e) => {
    try {
      e.preventDefault();
      if (!state.cancellation) {
        return setError('You must accept cancellation compliance - if you have any questions please contact us at hello@mamamobile.com.')
      }
      setLoading(true);
      setError(false);

      const res = await updateIntake({
        variables: {
          intake: {
            id: intake.id,
            complete: true,
          },
        }
      });

      setLoading(false);
      if (res.data.updateIntake.success) {
        history.push('/thank-you');
      } else {
        setError(res.data.updateIntake.message);
      }
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  }

  return (
    <div className='page'>
      {loading && <Loading />}
      <form className='panel-body flex-center'>
        <Step step={3} />
        <div className='compliance-check'>
          <p className='MuiFormLabel-root'>
            We have a 24 hour free cancellation policy. Because our professionals block out time for your treatment, if a cancellation is within the 24 hours, they will not be able to get another appointment, therefore, we have to charge the full amount for the treatment.
          </p>
          <BooleanSelector label="I agree to the cancellation policy" onChange={handleChange} selectedValue={state.cancellation} onChangeKey={'cancellation'} />
        </div>
        <div className='alert-box'>The following compliance is for <i>in-home massage</i> only, if you booked a virtual pelvic floor physiotherapy appointment and intend on getting a massage in the future please also fill this out.</div>
        <div className='compliance-check'>
          <p className='MuiFormLabel-root'>
            We kindly ask that all treatments take place on the main floor of your home. We do this for saftey purposes - to avoid injury to the Massage Therapist while carrying the 40lb table. If the RMT is asked to perform the treatment on a different floor, she/he has the ultimate decision to deny treatment.
          </p>
          <BooleanSelector label="I agree to treating on my main floor" onChange={handleChange} selectedValue={state.mainFloor} onChangeKey={'mainFloor'} />
        </div>
        {error && <Error error={error} />}
        <div className='margin-top-20'>
          <Button color="secondary" variant="contained" onClick={handleConfirmation}>
            Save and Continue
          </Button>
        </div>
      </form>
    </div>
  )
};

const mapStateToProps = (state) => ({
  intake: state.intake,
});

export default connect (mapStateToProps)(Confirmation);

const INFANT = [
  'problems feeding', 'difficulty opening mouth', 'Ear pain, tugging on ears', 'favours turning their head to one side',
  'reflux', 'difficulty passing gas', 'teething', 'colic',
  'difficulty with bowel movements', 'overly sensitive, quick to startle', 'not sleeping', 'fussy, hard to soothe',
  'Doesn’t like tummy time',
];

const initialState = {}; 
INFANT.map(item => initialState[item] = false);

const infant = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.infant;
    case 'CHANGE_INFANT':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default infant;
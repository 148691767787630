import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import changeIntakeInfo from '../../actions/changeIntakeInfo';
import { TextField, Button } from '@material-ui/core';
import RadioSelector from '../../Components/Selector/RadioSelector';
import MassageInfo from './MassageInfo';
import Step from '../../Components/Step/Step';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_INTAKE from '../../gql/mutation/updateIntake';
import Error from '../../Components/Error/Error';
import Loading from '../../Components/Lottie/Loading';
import PrenatalSelector from '../../Components/Selector/PrenatalSelector';
import BooleanSelector from '../../Components/Selector/BooleanSelector';

const NUMBER = ['1', '2', '3', '4', '5+'];
const BIRTHTYPE = [
  'assisted home birth', 'unassisted home birth', 'alternative birth center', 'hospital birth (unmedicated)',
  'hospital birth (medicated)', 'planned cesarean birth', 'non-emergent unplanned cesarean birth',
  'emergency cesarean birth', 'forceps or vacuum-assisted delivery', 'other',
];
const TRIMESTER = ['1', '2', '3'];
const RISKLEVEL = ['low risk', 'high risk'];

const MedicalInfo = ({ 
  intake, 
  changeIntakeInfo, 
  digestive,
  musculoSkeletal,
  nervousSystem,
  otherConditions,
  respiratory,
  prenatal,
  skin, 
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updateIntake, updateIntakeRes] = useMutation(UPDATE_INTAKE);

  const renderBusymama = () => {
    return (
      <>
        <RadioSelector options={NUMBER} label='I am a mama of ____!' selectedValue={intake.childrenNumber} onChange={changeIntakeInfo} onChangeKey='childrenNumber'/>
        <TextField
          id="date"
          label="When was your last birth?"
          type="date"
          variant="outlined"
          value={intake.birthDate}
          onChange={(e) => changeIntakeInfo('birthDate', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <BooleanSelector label='Check this box if you are currently suffering from depression or anxiety?' selectedValue={intake.hasPostpartumDepression} onChange={changeIntakeInfo} onChangeKey='hasPostpartumDepression' />
        {intake.hasPostpartumDepression && <TextField multiline rows="4" value={intake.postpartumDepression} onChange={(e) => changeIntakeInfo('postpartumDepression', e.target.value)} label="Please elaborate on how you are feeling..." variant="outlined" />}
      </>
    )
  }

  const renderPostnatal = () => {
    return (
      <>
        <RadioSelector options={NUMBER} label='I am a mama of ____!' selectedValue={intake.childrenNumber} onChange={changeIntakeInfo} onChangeKey='childrenNumber'/>
        <TextField
          id="date"
          label="When did you last give birth?"
          type="date"
          variant="outlined"
          value={intake.birthDate}
          onChange={(e) => changeIntakeInfo('birthDate', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <RadioSelector options={BIRTHTYPE} label='What kind of birth did you have?' selectedValue={intake.deliveryMethod} onChange={changeIntakeInfo} onChangeKey='deliveryMethod'/>
        <BooleanSelector label='Check this box if there are any complications during pregnancy or labor that your therapist should know about?' selectedValue={intake.hasBirthComplications} onChange={changeIntakeInfo} onChangeKey='hasBirthComplications' />
        {intake.hasBirthComplications && <TextField multiline rows="2" value={intake.birthComplications} onChange={(e) => changeIntakeInfo('birthComplications', e.target.value)} label="Please explain your birth complications in detail" variant="outlined" />}
        <BooleanSelector label='Check this box if you had or are currently suffering from postpartum depression?' selectedValue={intake.hasPostpartumDepression} onChange={changeIntakeInfo} onChangeKey='hasPostpartumDepression' />
        {intake.hasPostpartumDepression && <TextField multiline rows="4" value={intake.postpartumDepression} onChange={(e) => changeIntakeInfo('postpartumDepression', e.target.value)} label="Please elaborate on how you are feeling..." variant="outlined" />}
      </>
    )
  }

  const renderPrenatal = () => {
    return (
      <>
        <RadioSelector options={NUMBER} label='This is my ____ (number) pregnancy. (include miscariages and terminations).' selectedValue={intake.pregnancyNumber} onChange={changeIntakeInfo} onChangeKey='pregnancyNumber'/>
        <RadioSelector options={NUMBER} label='This is my ____ (number) birth.' selectedValue={intake.birthNumber} onChange={changeIntakeInfo} onChangeKey='birthNumber'/>
        <RadioSelector options={TRIMESTER} label='I am in my _____ (number) trimester!' selectedValue={intake.trimester} onChange={changeIntakeInfo} onChangeKey='trimester'/>
        <TextField
          id="date"
          label="Due Date"
          type="date"
          variant="outlined"
          value={intake.dueDate}
          onChange={(e) => changeIntakeInfo('dueDate', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <PrenatalSelector />
        <TextField multiline rows="2" value={intake.prenatalConditionNotes} onChange={(e) => changeIntakeInfo('prenatalConditionNotes', e.target.value)} label="Please explain your other prenatal problems in detail!" variant="outlined" />
        <RadioSelector options={RISKLEVEL} label='Is your pregnancy high risk or low risk according to your doctor/midwife?' selectedValue={intake.pregnancyRisk} onChange={changeIntakeInfo} onChangeKey='pregnancyRisk'/>
        <BooleanSelector label='Check this box if your primary care for your pregnancy differs from doctor.' selectedValue={intake.hasMidwife} onChange={changeIntakeInfo} onChangeKey='hasMidwife'/>
        {intake.hasMidwife && <TextField value={intake.midwife} onChange={(e) => changeIntakeInfo('midwife', e.target.value)} label="Midwife and/or Doula Name" variant="outlined" />}
        {intake.hasMidwife && <TextField value={intake.midwifeTel} onChange={(e) => changeIntakeInfo('midwifeTel', e.target.value)} label="Midwife and/or Doula Phone" variant="outlined" />}
      </>
    )
  };

  const stateToString = (state) => {
    const stateArr = Object.keys(state).filter(key => state[key]);
    return stateArr.join(' | ');
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError(false);
      const intakeForm = { 
        ...intake,
        digestive: stateToString(digestive),
        musculoSkeletal: stateToString(musculoSkeletal),
        nervousSystem: stateToString(nervousSystem),
        otherConditions: stateToString(otherConditions),
        respiratory: stateToString(respiratory),
        skin: stateToString(skin), 
        existingPrenatalConditions: stateToString(prenatal),
      };

      delete intakeForm.isPhysioOnly;

      console.log(intakeForm);

      const res = await updateIntake({
        variables: {
          intake: intakeForm,
        }
      });
      console.log(res);

      setLoading(false);
      if (res.data.updateIntake.success) {
        history.push('/step-3');
      } else {
        setError(res.data.updateIntake.message);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(err);
    }
  };

  if (!intake.id) {
    history.push('/home/b4e35b03-792c-4988-b070-fe453483d70e');
  };

  return (
    <div className='page'>
      {loading && <Loading />}
      <form className='panel-body flex-center' onSubmit={handleSubmit}>
        <Step step={2} />
        {intake.userType === 'busymama' && renderBusymama()}
        {intake.userType === 'postnatal' && renderPostnatal()}
        {intake.userType === 'prenatal' && renderPrenatal()}
        <MassageInfo />
        {error && <Error error={error} />}
        <div className='margin-top-20'>
          <Button type="submit" color="secondary" variant="contained">
            Save and Continue
          </Button>
        </div>
      </form>
    </div>
  )
};

const mapStateToProps = (state) => ({
  intake: state.intake,
  digestive: state.digestive,
  musculoSkeletal: state.musculoSkeletal,
  nervousSystem: state.nervousSystem,
  otherConditions: state.otherConditions,
  respiratory: state.respiratory,
  prenatal: state.prenatal,
  skin: state.skin,
});

const mapDispatchToProps = dispatch => ({
  changeIntakeInfo: (key, value) => {
    dispatch(changeIntakeInfo(key, value))
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(MedicalInfo);

const PRENATAL = [
  'anemia', 'leaking amniotic fluid', 'bladder infection', 'uterine bleeding', 'blood clot or phlebitis',
  'chronic hypertension', 'abdominal cramping', 'diabetes (gestational or mellitus)', 'edema/swelling',
  'fatigue', 'headaches', 'insomnia', 'high blood pressure', 'leg cramps', 'miscarriage', 'nausea',
  'problem with placenta', 'pre-term labour', 'preeclampsia (toxemia)', 'sciatica', 'separation of the rectus muscles',
  'separation of the symphysis pubis', 'skin disorders/athletes foot', 'twins or more!', 'varicose veins',
  'visual disturbances', 'previous cesarean birth', 'muscle sprain/strain', 'hypo or hyperglycemia', 
  'heart attack/stroke', 'arthritis', 'carpal tunnel syndrome', 'low blood pressure', 'bursitis', 'other prenatal problems'
];

const initialState = {}; 
PRENATAL.map(item => initialState[item] = false);

const prenatal = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.prenatal;
    case 'CHANGE_PRENATAL':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default prenatal;
import React from 'react';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { changeSkin } from '../../actions/changeHealthInfo';

const OPTIONS = [
  'rashes', 'allergies', `athlete's foot`, 'acne', 'impetigo', 'hemophilia'
];

const SkinSelector = ({ changeHealthInfo, state }) => {
  let options1;
  let options2;

  if (OPTIONS.length % 2 === 0) {
    options1 = OPTIONS.slice(0, OPTIONS.length/2);
    options2 = OPTIONS.slice(OPTIONS.length/2);
  } else {
    options1 = OPTIONS.slice(0, OPTIONS.length/2 + 1);
    options2 = OPTIONS.slice(OPTIONS.length/2 + 1);
  }

  return (
    <div className='checkbox-wrapper'>
      <FormControl component="fieldset">
        <FormLabel component="legend">Check the following "skin" conditions that apply to you, past and present:</FormLabel>
        <FormGroup>
          <div className='checkbox-set-wrapper'>
            <div className='checkbox-set'>
              {options1.map(ref => (
                 <FormControlLabel
                  key={ref}
                  control={<Checkbox checked={state[ref]} onChange={(e) => changeHealthInfo(ref)} value={ref} />}
                  label={ref}
                />
              ))}
            </div>
            <div className='checkbox-set'>
              {options2.map(ref => (
                <FormControlLabel
                  key={ref}
                  control={<Checkbox checked={state[ref]} onChange={(e) => changeHealthInfo(ref)} value={ref} />}
                  label={ref}
                />
              ))}
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state.skin,
});

const mapDispatchToProps = dispatch => ({
  changeHealthInfo: key => {
    dispatch(changeSkin(key))
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(SkinSelector);
const initialState = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  tel: '',
  birthday: '',
  gender: '',
  genderNotes: '',
  referralSource: '',
  referralNotes: '',
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.user;
    case 'CHANGE_USER_INFO':
      return {
        ...state,
        [action.key]: action.value,
      }
    default:
      return state;
  }
}

export default user;
import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const SelectorWithBorder = ({ required, label, selectedValue, onChange, onChangeKey, options }) => {
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <FormControl required={required} variant="outlined">
      <InputLabel ref={inputLabel}>
        {label}
      </InputLabel>
      <Select
        value={selectedValue}
        onChange={(e) => onChange(onChangeKey, e.target.value)}
        required
        labelWidth={labelWidth}
      >
        {options.map(ref => <MenuItem key={ref} value={ref}>{ref}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default SelectorWithBorder;
import React from 'react';

const Step = ({ step }) => {
  return (
    <div>
      {`Step ${step} of 3`}
    </div>
  )
};

export default Step;
import React from 'react'
import Lottie from 'react-lottie';
import animationData from './celebrate.json';

const LottieCustomize = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
 
    return (
      <div>
        <Lottie 
          options={defaultOptions}
          height={250}
          width={250}
          isClickToPauseDisabled={true} 
        />
      </div>
    )
}

export default LottieCustomize;

 
import { gql } from 'apollo-boost';

const GET_INTAKE = gql`
  query Intake($id: ID) {
    intake(id: $id) {
      id
      complete
      emergencyContact
      emergencyContactTel
      emergencyContactRel
      familyDoctor
      familyDoctorTel
      familyDoctorAddress
      occupation
      insurance
      massageFrequency
      userType
      user {
        id
        firstName
        lastName
        email
        tel
        birthday
        gender
        genderNotes
        referralSource
        referralNotes
        address {
          id
          unit
          buzzer
          street
          city
          province
          country
          postalCode
          homeType
          hasStairs
          stairsNotes
          parkingType
          parkingNotes
        }
      }

      # common questions
      hasMedication
      medication
      hasChronicPain
      chronicPain
      hasSurgeryOrInjury
      surgeryOrInjury
      musculoSkeletal
      respiratory
      digestive
      nervousSystem
      skin
      otherConditions
      conditionNotes
      hadMassageBefore
      lastMassage
      otherTreatments
      massageType
      massagePressure
      hasAllergies
      allergies
      hasNoMassageArea
      noMassageArea
      hasTreatmentGoal
      treatmentGoal
      extraNotes

      # prenatal 
      pregnancyNumber
      birthNumber
      trimester
      dueDate
      existingPrenatalConditions
      pregnancyRisk
      hasMidwife
      midwife
      midwifeTel
      prenatalConditionNotes


      # postnatal & busy mama
      birthDate
      deliveryMethod
      childrenNumber
      hasBirthComplications
      birthComplications
      hasPostpartumDepression
      postpartumDepression

      # baby 
      weeksGestation
      hasPostpartumComplication
      postpartumComplication
    }
  }
`;

export default GET_INTAKE;
const RESPIRATORY = [
  'dizziness', 'shortness of breath', 'fainting', 'cold feet or hands', 'cold sweats', 'stroke',
  'heart condition', 'allergies', 'asthma', 'high blood pressure', 'low blood pressure'
];

const initialState = {}; 
RESPIRATORY.map(item => initialState[item] = false);

const respiratory = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.respiratory;
    case 'CHANGE_RESPIRATORY':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default respiratory;
const NERVOUSSYSTEM = [
  'numbness/tingling', 'fatigue', 'sleep disorders', 'ulcers', 'paralysis', 'herpes/shingles',
  'cerebral palsy', 'epilepsy', 'chronic fatigue syndrome', 'multiple sclerosis', 'muscular dystrophy',
  `parkinson's disease`,
];

const initialState = {}; 
NERVOUSSYSTEM.map(item => initialState[item] = false);

const nervousSystem = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.nervousSystem;
    case 'CHANGE_NERVOUSSYSTEM':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default nervousSystem;
import React from 'react';
import TextField from '@material-ui/core/TextField';
import RadioSelector from '../../Components/Selector/RadioSelector';
import SelectorWithBorder from '../../Components/Selector/SelectorWithBorder';
import BooleanSelector from '../../Components/Selector/BooleanSelector';
import { connect } from 'react-redux';
import changeIntakeInfo from '../../actions/changeIntakeInfo';
import MusculoSkeletalSelector from '../../Components/Selector/MusculoSkeletalSelector';
import RespiratorySelector from '../../Components/Selector/RespiratorySelector';
import OtherHealthSelector from '../../Components/Selector/OtherHealthSelector';
import NervousSystemSelector from '../../Components/Selector/NervousSystemSelector';
import SkinSelector from '../../Components/Selector/SkinSelector';
import DigestiveSelector from '../../Components/Selector/DigestiveSelector';

const LASTMASSAGE = ['', 'one week', 'two weeks', '1 month', '2 months', '3 months', '6 months', '1 year', '1 year+'];
const MASSAGETYPE = ['relaxation', 'deep tissue'];
const MASSAGEPRESSURE = ['light', 'medium', 'deep'];
const INSURANCE = ['', `I don't have benefits`, 'under $500', '$501 - $749', '750 - $999', '$1000 - $1500', '$1500+'];
const MASSAGEHABIT = ['', 'I get massages once a week', 'I get massages every two weeks', 'I get massages once a month', 'I get massages on a need basis', 'I try to use up my insurance!', 'I save massages for special occassions only'];

const MassageInfo = ({ intake, changeIntakeInfo }) => {
  return (
    <>
      <TextField required value={intake.emergencyContact} onChange={(e) => changeIntakeInfo('emergencyContact', e.target.value)} label="Emergency Contact Name" variant="outlined" />
      <TextField required value={intake.emergencyContactTel} onChange={(e) => changeIntakeInfo('emergencyContactTel', e.target.value)} label="Emergency Contact Phone" variant="outlined" />
      <TextField required value={intake.emergencyContactRel} onChange={(e) => changeIntakeInfo('emergencyContactRel', e.target.value)} label="Emergency Contact Relationship" variant="outlined" />
      <TextField required value={intake.familyDoctor} onChange={(e) => changeIntakeInfo('familyDoctor', e.target.value)} label="Family Doctor" variant="outlined" />
      <TextField value={intake.familyDoctorTel} onChange={(e) => changeIntakeInfo('familyDoctorTel', e.target.value)} label="Family Doctor Phone" variant="outlined" />
      <TextField value={intake.familyDoctorAddress} onChange={(e) => changeIntakeInfo('familyDoctorAddress', e.target.value)} label="Family Doctor Address" variant="outlined" />
      <TextField value={intake.occupation} onChange={(e) => changeIntakeInfo('occupation', e.target.value)} label="Occupation" variant="outlined" />
      <SelectorWithBorder selectedValue={intake.insurance} onChange={changeIntakeInfo} onChangeKey='insurance' label='Which insurance category suits you best?' options={INSURANCE} />
      <BooleanSelector label='Check this box if you are taking any medications.' selectedValue={intake.hasMedication} onChange={changeIntakeInfo} onChangeKey='hasMedication' />
      {intake.hasMedication && <TextField multiline rows="2" value={intake.medication} onChange={(e) => changeIntakeInfo('medication', e.target.value)} label="What medications are you taking and why?" variant="outlined" />}
      <BooleanSelector label='Check this box if you suffer from chronic pain.' selectedValue={intake.hasChronicPain} onChange={changeIntakeInfo} onChangeKey='hasChronicPain' />
      {intake.hasChronicPain && <TextField multiline rows="2" value={intake.chronicPain} onChange={(e) => changeIntakeInfo('chronicPain', e.target.value)} label="Please explain your pain in detail, including what makes it better and what makes it worse." variant="outlined" />}
      <BooleanSelector label='Check this box if you had any surgical procedure or injury.' selectedValue={intake.hasSurgeryOrInjury} onChange={changeIntakeInfo} onChangeKey='hasSurgeryOrInjury'/>
      {intake.hasSurgeryOrInjury && <TextField multiline rows="2" value={intake.surgeryOrInjury} onChange={(e) => changeIntakeInfo('surgeryOrInjury', e.target.value)} label="Please explain your surgery or injury in detail" variant="outlined" />}
      <MusculoSkeletalSelector />
      <RespiratorySelector />
      <NervousSystemSelector />
      <DigestiveSelector />
      <SkinSelector />
      <OtherHealthSelector />
      <TextField multiline rows="2" value={intake.conditionNotes} onChange={(e) => changeIntakeInfo('conditionNotes', e.target.value)} label="Please explain in detail regarding any of the above checked health conditions" variant="outlined" />
      <TextField multiline rows="2" value={intake.otherTreatments} onChange={(e) => changeIntakeInfo('otherTreatments', e.target.value)} label="Please let us know what other treatments or therapies you are currently using." variant="outlined" />
      <BooleanSelector ariaLabel='allergies or sensitivities' label='Check this box if you have any allergies or sensitivities.' selectedValue={intake.hasAllergies} onChange={changeIntakeInfo} onChangeKey='hasAllergies' />
      {intake.hasAllergies && <TextField multiline rows="2" value={intake.allergies} onChange={(e) => changeIntakeInfo('allergies', e.target.value)} label="Please explain your allergies or sensitivities in detail" variant="outlined" />}
      <BooleanSelector ariaLabel='goals of treatment' label='Check this box if you have any particular goal in mind for this treatment.' selectedValue={intake.hasTreatmentGoal} onChange={changeIntakeInfo} onChangeKey='hasTreatmentGoal' />
      {intake.hasTreatmentGoal && <TextField multiline rows="2" value={intake.treatmentGoal} onChange={(e) => changeIntakeInfo('treatmentGoal', e.target.value)} label="Please explain your treatment goal in detail" variant="outlined" />}
      <TextField multiline rows="2" value={intake.extraNotes} onChange={(e) => changeIntakeInfo('extraNotes', e.target.value)} label="Is there anything else you'd like us to know?" variant="outlined" />
      <div className='alert-box'>The following questions are for <i>in-home massage</i> only, if you booked a virtual pelvic floor physiotherapy appointment and intend on getting a massage in the future please also fill this out. </div>
      <BooleanSelector label='Check this box if you had Registered Massage Therapy before.' selectedValue={intake.hadMassageBefore} onChange={changeIntakeInfo} onChangeKey='hadMassageBefore'/>
      {intake.hadMassageBefore && <SelectorWithBorder ariaLabel='last massage date' selectedValue={intake.lastMassage} onChange={changeIntakeInfo} onChangeKey='lastMassage' label='When was your last massage?' options={LASTMASSAGE} />}
      <RadioSelector ariaLabel='massage type' options={MASSAGETYPE} label='What type of massage are you seeking?' selectedValue={intake.massageType} onChange={changeIntakeInfo} onChangeKey='massageType'/>
      <RadioSelector ariaLabel='massage pressure' options={MASSAGEPRESSURE} label='What pressure do you prefer?' selectedValue={intake.massagePressure} onChange={changeIntakeInfo} onChangeKey='massagePressure'/>
      <SelectorWithBorder selectedValue={intake.massageFrequency} onChange={changeIntakeInfo} onChangeKey='massageFrequency' label='Which massage style suits you best?' options={MASSAGEHABIT} />
      <BooleanSelector ariaLabel='body areas to exclude during massage' label="Check this box if there are any areas you do NOT want massaged." selectedValue={intake.hasNoMassageArea} onChange={changeIntakeInfo} onChangeKey='hasNoMassageArea' />
      {intake.hasNoMassageArea && <TextField multiline rows="2" value={intake.noMassageArea} onChange={(e) => changeIntakeInfo('noMassageArea', e.target.value)} label="Please explain what areas you would like to avoid" variant="outlined" />}
    </>
  )
};

const mapStateToProps = (state) => ({
  intake: state.intake,
});

const mapDispatchToProps = dispatch => ({
  changeIntakeInfo: (key, value) => {
    dispatch(changeIntakeInfo(key, value))
  },
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(MassageInfo);

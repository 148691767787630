import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import store from './store'
import './App.css';
import LandingPage from '../src/Pages/LandingPage/LandingPage';
import PersonalInfo from './Pages/IntakeForm/PersonalInfo';
import UserOption from './Pages/IntakeForm/UserOption';
import ScrollToTop from './Components/ScrollToTop';
import MedicalInfo from './Pages/IntakeForm/MedicalInfo';
import Infant from './Pages/IntakeForm/Infant';
import { IntakeContext } from './context';
import Confirmation from './Pages/IntakeForm/Confirmation';
import theme from './theme.js';
import Thankyou from './Pages/Thankyou/Thankyou';
import SelectIntake from './Pages/IntakeForm/SelectIntake';

const client = new ApolloClient({
  uri: 'https://api.mamamobile.com/graphql',
  // uri: 'http://localhost:4000/graphql',
});

const themeCustomize = createMuiTheme(theme);

class App extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      intakeId: null,
      setState: (key, value) => {
        this.setState({ [key]: value })
      }
    };
  }
  
  render() {
    return (
      <IntakeContext.Provider value={this.state}>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <ThemeProvider theme={themeCustomize}>
              <Router>
                <div className='page-outter'>
                  <ScrollToTop />
                  <Switch>
                    <Route path="/home/:id/:treatment" component={LandingPage} />
                    <Route path="/home/:id" component={LandingPage} />
                    <Route path="/select-intake" component={SelectIntake} />
                    <Route path="/step-1" component={PersonalInfo} />
                    <Route path="/user-option" component={UserOption} />
                    <Route path="/step-2-infant" component={Infant} />
                    <Route path="/step-2" component={MedicalInfo} />
                    <Route path="/step-3" component={Confirmation} />
                    <Route path="/thank-you" component={Thankyou} />
                  </Switch>
                </div>
              </Router>
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </IntakeContext.Provider>
    );
  }
}

export default App;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { TextField, Button } from '@material-ui/core';
import SelectorWithBorder from '../../Components/Selector/SelectorWithBorder';
import { useMutation } from '@apollo/react-hooks';
import UPDATE_INTAKE from '../../gql/mutation/updateIntake';
import BooleanSelector from '../../Components/Selector/BooleanSelector';
import Error from '../../Components/Error/Error';
import Step from '../../Components/Step/Step';
import changeAddressInfo from '../../actions/changeAddressInfo';
import changeUserInfo from '../../actions/changeUserInfo';
import Loading from '../../Components/Lottie/Loading';

const GENDER = ['female', 'male', 'other'];
const REFERRAL = ['Friend', 'Facebook', 'Instagram', 'Google Search', 'Google Ads', 'Facebook/Instagram Ads', 'Physician/Specialist', 'Mommy Connection', 'MomsTO', 'Other'];
const HOMETYPE = ['apartment/condo', 'townhouse', 'house'];
const PARKING = ['driveway parking', 'visitor parking', `free street parking`, `pay parking`];

const PersonalInfo = ({ intakeId, user, address, changeAddressInfo, changeUserInfo, isPhysioOnly }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [updateIntake, updateIntakeRes] = useMutation(UPDATE_INTAKE);

  if (!intakeId) {
    history.push('/home/b4e35b03-792c-4988-b070-fe453483d70e');
  };

  const capitalize = (str) => {
    let result = str.trim();
    result = result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    return result;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(false);
    if (user.tel.length !== 10) {
      setError('Please make sure to only include the 10 digits of your number! Eg. 4161234567');
      setLoading(false);
    } else {
      const firstName = capitalize(user.firstName.trim());
      const lastName = capitalize(user.lastName.trim());
      const email = user.email.toLowerCase();
      const newUser = {
        ...user,
        firstName,
        lastName,
        email,
      };
  
      try {
        const variables = {
          user: newUser,
          address,
        };
    
        const { data } = await updateIntake({ 
          variables,
        });
        
        if (data.updateIntake.success) {
          setLoading(false);
          setError(false);
          history.push('/user-option');
        } else {
          setLoading(false);
          setError(data.updateIntake.message);
        }
      } catch (err) {
        setLoading(false);
        setError(err);
      }
    }
  }

  const referralExtraInfo = (referral) => {
    let label = 'Let us know how you found us!';

    if (referral === 'Friend') {
      label = 'Let us know the name of your friend!';
    } else if (referral === 'Physician/Specialist') {
      label = 'Let us know the name of your specialist!';
    }

    if (referral === 'Friend' || referral === 'Physician/Specialist' || referral === 'Other') {
      return (
        <TextField
          value={user.referralNotes}
          onChange={(e) => changeUserInfo('referralNotes', e.target.value)}
          label={label}
          variant="outlined" 
        />
      )
    }
  };

  const renderParkingNote = () => {
    let placeholder;

    if (address.parkingType === 'visitor parking') {
      placeholder = "Please explain in detail how our RMT can get into the visitor parking and go from the visitor parking to your home!";
    } else if (address.parkingType === 'free street parking') {
      placeholder = "Please explain in detail where the free street parking is. If the street parking is further than 200m to your home, could your RMT arrange to drop off his/her table and equipment at the door first? (Please ensure the hour limitation on street parking is at least 30 minutes longer than your massage - we don't want our RMTs getting tickets!)";
    } else if (address.parkingType === 'pay parking') {
      placeholder = "You are responsible for any parking fees incurred during the massage. Please explain in detail where the closest pay parking is. Also, it would be great if you can reimburse your RMT on site with cash. If not, we will have to add on a 5% processing fee for the parking.";
    } else if (address.parkingType === 'driveway parking') {
      placeholder = "Any additional notes for your RMT regarding parking?";
    }

    return (
      <TextField
        id="parking-notes"
        multiline
        rows="4"
        value={address.parkingNotes}
        onChange={e => changeAddressInfo('parkingNotes', e.target.value)}
        placeholder={placeholder}
        variant="outlined"
      />
    )
  }

  return (
    <div className='page'>
      {loading && <Loading />}
      <form className='panel-body flex-center' onSubmit={handleSubmit}>
        <Step step='1' />
        <TextField required value={user.firstName} onChange={(e) => changeUserInfo('firstName', e.target.value)} label="First Name" variant="outlined" />
        <TextField required value={user.lastName} onChange={(e) => changeUserInfo('lastName', e.target.value)} label="Last Name" variant="outlined" />
        <TextField required value={user.email} onChange={(e) => changeUserInfo('email', e.target.value)} label="Email" variant="outlined" />
        <TextField required value={user.tel} onChange={(e) => changeUserInfo('tel', e.target.value)} label="Phone (only the 10 digits eg. 4161234567)" variant="outlined" />
        <TextField
          required
          id="date"
          label="Birthday"
          type="date"
          variant="outlined"
          value={user.birthday}
          onChange={(e) => changeUserInfo('birthday', e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <SelectorWithBorder required selectedValue={user.gender} onChange={changeUserInfo} onChangeKey='gender' label='Gender' options={GENDER} />
        {(user.gender === 'other') && <TextField value={user.genderNotes} onChange={(e) => changeUserInfo('genderNotes', e.target.value)} label="How do you identify?" variant="outlined" />}
        <TextField required value={address.street} onChange={(e) => changeAddressInfo('street', e.target.value)} label="Street Address" variant="outlined" />
        <SelectorWithBorder required selectedValue={user.referralSource} onChange={changeUserInfo} onChangeKey='referralSource' label='How did you hear about us?' options={REFERRAL} />
        {referralExtraInfo(user.referralSource)}
        <TextField value={address.unit} onChange={(e) => changeAddressInfo('unit', e.target.value)} label="Unit Number" variant="outlined" />
        <TextField required value={address.city} onChange={(e) => changeAddressInfo('city', e.target.value)} label="City" variant="outlined" />
        <TextField required value={address.province} onChange={(e) => changeAddressInfo('province', e.target.value)} label="Province" variant="outlined" />
        <TextField required value={address.postalCode} onChange={(e) => changeAddressInfo('postalCode', e.target.value)} label="Postal Code" variant="outlined" />
        <TextField required value={address.country} onChange={(e) => changeAddressInfo('country', e.target.value)} label="Country" variant="outlined" />
        <div className='alert-box'>The following questions are for <i>in-home massage</i> only, if you booked a virtual pelvic floor physiotherapy appointment and intend on getting a massage in the future please also fill this out. </div>
        <p style={{marginTop: '20px'}}>Please note that our massage tables are HEAVY (~40lbs). In order to deliver the best experience, we need to ensure we have clear instructions for your RMT to get to your front door! If you do have a complicated spot or long walk we ask that you kindly meet her at the front of the building so that she can pull up and let her things off before parking.</p>
        <SelectorWithBorder required selectedValue={address.homeType} onChange={changeAddressInfo} onChangeKey='homeType' label='Which would best describe your home' options={HOMETYPE} />
        {address.homeType === 'apartment/condo' && <TextField value={address.buzzer} onChange={(e) => changeAddressInfo('buzzer', e.target.value)} label="Buzzer Number" variant="outlined" />}
        <BooleanSelector label='Check this box if there are stairs to get to your front door?' selectedValue={address.hasStairs} onChange={changeAddressInfo} onChangeKey='hasStairs' />
        {address.hasStairs && <TextField multiline rows="2" value={address.stairsNotes} onChange={(e) => changeAddressInfo('stairsNotes', e.target.value)} label="Please explain how many stairs there are to get to your front door!" variant="outlined" />}
        <SelectorWithBorder required selectedValue={address.parkingType} onChange={changeAddressInfo} onChangeKey='parkingType' label='Which would best describe your parking?' options={PARKING} />
        {address.parkingType && renderParkingNote()}
        {error && <Error error={error} />}
        <div className='margin-top-20'>
          <Button type="submit" color="secondary" variant="contained">
            Save and Continue
          </Button>
        </div>
      </form>
    </div>
  )
};

const mapStateToProps = (state) => ({
  intakeId: state.intake.id,
  isPhysioOnly: state.intake.isPhysioOnly,
  user: state.user,
  address: state.address,
});

const mapDispatchToProps = dispatch => ({
  changeUserInfo: (key, value) => {
    dispatch(changeUserInfo(key, value))
  },
  changeAddressInfo: (key, value) => {
    dispatch(changeAddressInfo(key, value))
  },
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(PersonalInfo);

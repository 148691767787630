const changeMusculoSkeletal = (key) => {
  return {
    type: 'CHANGE_MUSCULOSKELETAL',
    key,
  }
};

const changeRespiratory = (key) => {
  return {
    type: 'CHANGE_RESPIRATORY',
    key,
  }
};

const changeOtherHealth = (key) => {
  return {
    type: 'CHANGE_OTHER_CONDITIONS',
    key,
  }
};

const changeDigestive = (key) => {
  return {
    type: 'CHANGE_DIGESTIVE',
    key,
  }
};

const changeNervousSystem = (key) => {
  return {
    type: 'CHANGE_NERVOUSSYSTEM',
    key,
  }
};

const changeSkin = (key) => {
  return {
    type: 'CHANGE_SKIN',
    key,
  }
};

const changePrenatal = (key) => {
  return {
    type: 'CHANGE_PRENATAL',
    key,
  }
};

const changeInfant = (key) => {
  return {
    type: 'CHANGE_INFANT',
    key,
  }
};

export {
  changeMusculoSkeletal,
  changeRespiratory,
  changeOtherHealth,
  changeDigestive,
  changeNervousSystem,
  changeSkin,
  changePrenatal,
  changeInfant,
};
import React from 'react';
import LottieCustomize from '../../Components/Lottie/LottieCustomize';
import './thankyou.css';

const Thankyou = () => {
  return (
    <div className='thank-you'>
      <h1>Mama Mobile</h1>
      <LottieCustomize />
      <h5>Thank you for filling out your intake form.</h5>
      <h5>We can’t wait to see you!</h5>
    </div>
  )
};

export default Thankyou;
const SKIN = ['rashes', 'allergies', `athlete's foot`, 'acne', 'impetigo', 'hemophilia'];

const initialState = {}; 
SKIN.map(item => initialState[item] = false);

const skin = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.skin;
    case 'CHANGE_SKIN':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default skin;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import changeIntakeInfo from '../../actions/changeIntakeInfo';

const SelectIntake = ({ changeIntakeInfo }) => {
  const history = useHistory();

  const handleClick = (boolean) => {
    changeIntakeInfo('isPhysioOnly', boolean);
    history.push('/step-1');
  };

  return (
    <div className='intake-option'>
      <h4>You booked a pelvic floor physio appointment, please let us know if you are interested in getting in-home massage in the future.</h4>
      <h4>If so, we can optimize your intake so you won't need to fill out a new intake form for when you book your massage!</h4>
      <button onClick={() => handleClick(false)} className='button-outline lightGreen'>
        I might be interested in in-home massage
      </button>
      <button onClick={() => handleClick(true)} className='button-outline teal'>
        I do not plan on getting an in-home massage
      </button>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  changeIntakeInfo: (key, value) => {
    dispatch(changeIntakeInfo(key, value))
  }
});

export default connect (
  null,
  mapDispatchToProps
)(SelectIntake);

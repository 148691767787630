import { combineReducers } from 'redux';
import user from './user';
import intake from './intake';
import address from './address';
import musculoSkeletal from './musculoSkeletal';
import respiratory from './respiratory';
import otherConditions from './otherConditions';
import digestive from './digestive';
import nervousSystem from './nervousSystem';
import skin from './skin';
import prenatal from './prenatal';
import infant from './infant';

export default combineReducers({ 
  user, 
  intake, 
  address, 
  musculoSkeletal,
  respiratory,
  otherConditions,
  digestive,
  nervousSystem,
  skin,
  prenatal,
  infant,
});
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const BooleanSelector = ({ ariaLabel, label, onChange, selectedValue, onChangeKey }) => {
  return (
    <FormControlLabel
      style={{width: '100%', marginTop: '20px', color: '#444'}}
      control={
        <Checkbox
          checked={selectedValue}
          onChange={() => onChange(onChangeKey, !selectedValue)}
          inputProps={{ 'aria-label': ariaLabel }}
        />
      }
      label={label}
    />
  )
}

export default BooleanSelector;
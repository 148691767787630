import React from 'react';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { changePrenatal } from '../../actions/changeHealthInfo';

const OPTIONS = [
  'anemia', 'leaking amniotic fluid', 'bladder infection', 'uterine bleeding', 'blood clot or phlebitis',
  'chronic hypertension', 'abdominal cramping', 'diabetes (gestational or mellitus)', 'edema/swelling',
  'fatigue', 'headaches', 'insomnia', 'high blood pressure', 'leg cramps', 'miscarriage', 'nausea',
  'problem with placenta', 'pre-term labour', 'preeclampsia (toxemia)', 'sciatica', 'separation of the rectus muscles',
  'separation of the symphysis pubis', 'skin disorders/athletes foot', 'twins or more!', 'varicose veins',
  'visual disturbances', 'previous cesarean birth', 'muscle sprain/strain', 'hypo or hyperglycemia', 
  'heart attack/stroke', 'arthritis', 'carpal tunnel syndrome', 'low blood pressure', 'bursitis', 'other prenatal problems'
];

const PrenatalSelector = ({ changeHealthInfo, state }) => {
  let options1;
  let options2;

  if (OPTIONS.length % 2 === 0) {
    options1 = OPTIONS.slice(0, OPTIONS.length/2);
    options2 = OPTIONS.slice(OPTIONS.length/2);
  } else {
    options1 = OPTIONS.slice(0, OPTIONS.length/2 + 1);
    options2 = OPTIONS.slice(OPTIONS.length/2 + 1);
  }

  return (
    <div className='checkbox-wrapper'>
      <FormControl component="fieldset">
        <FormLabel component="legend">Check the following conditions that apply to you during this pregnancy:</FormLabel>
        <FormGroup>
          <div className='checkbox-set-wrapper'>
            <div className='checkbox-set'>
              {options1.map(ref => (
                 <FormControlLabel
                  key={ref}
                  control={<Checkbox checked={state[ref]} onChange={(e) => changeHealthInfo(ref)} value={ref} />}
                  label={ref}
                />
              ))}
            </div>
            <div className='checkbox-set'>
              {options2.map(ref => (
                <FormControlLabel
                  key={ref}
                  control={<Checkbox checked={state[ref]} onChange={(e) => changeHealthInfo(ref)} value={ref} />}
                  label={ref}
                />
              ))}
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state.prenatal,
});

const mapDispatchToProps = dispatch => ({
  changeHealthInfo: key => {
    dispatch(changePrenatal(key))
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(PrenatalSelector);
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import './landingPage.css';
import { useQuery } from '@apollo/react-hooks';
import { Button } from '@material-ui/core';
import GET_INTAKE from '../../gql/query/intake';
import Loading from '../../Components/Lottie/Loading';
import initialLoad from '../../actions/initialLoad';


const LandingPage = ({ 
  user,
  address,
  intake, 
  initialLoad, 
  musculoSkeletal,
  nervousSystem,
  digestive,
  otherConditions,
  respiratory,
  skin, 
  prenatal,
  infant,
}) => {
  let { id } = useParams();
  const history = useHistory();
  const updateOtherHealth = (currentState, updateString) => {
    if (updateString) {
      const updateArr = updateString.split(' | ');
      const newState = { ...currentState };
      updateArr.forEach(item => {
        newState[item] = true
      });
      return newState;
    }

    return currentState;
  }
  const handleNext = () => {
    const { 
      firstName,
      lastName,
      email,
      tel,
      birthday,
      gender,
      genderNotes,
      referralSource,
      referralNotes,
    } = data.intake.user;
    const {
      unit,
      buzzer,
      street,
      city,
      province,
      country,
      postalCode,
      homeType,
      hasStairs,
      stairsNotes,
      parkingType,
      parkingNotes,
    } = data.intake.user.address;

    const {
      emergencyContact,
      emergencyContactTel,
      emergencyContactRel,
      familyDoctor,
      familyDoctorTel,
      familyDoctorAddress,
      occupation,
      insurance,
      massageFrequency,
      userType,
      hasMedication,
      medication,
      hasChronicPain,
      chronicPain,
      hasSurgeryOrInjury,
      surgeryOrInjury,
      conditionNotes,
      hadMassageBefore,
      lastMassage,
      massageType,
      massagePressure,
      otherTreatments,
      hasAllergies,
      allergies,
      hasNoMassageArea,
      noMassageArea,
      hasTreatmentGoal,
      treatmentGoal,
      extraNotes,
    
      // postnatal
      birthDate,
      deliveryMethod,
      childrenNumber,
      hasBirthComplications,
      birthComplications,
      hasPostpartumDepression,
      postpartumDepression,
    
      // prenatal 
      pregnancyNumber,
      birthNumber,
      trimester,
      dueDate,
      pregnancyRisk,
      hasMidwife,
      midwife,
      midwifeTel,
      prenatalConditionNotes,
    
      // baby 
      weeksGestation,
      postpartumComplication,
      hasPostpartumComplication,

    } = data.intake;
    const state = {
      user: {
        id: data.intake.user.id ? data.intake.user.id : user.id,
        firstName: firstName ? firstName : user.firstName,
        lastName: lastName ? lastName : user.lastName,
        email: email ? email : user.email,
        tel: tel ? tel : user.tel,
        birthday: birthday ? birthday : user.birthday,
        gender: gender ? gender : user.gender,
        genderNotes: genderNotes ? genderNotes : user.genderNotes,
        referralSource: referralSource ? referralSource : user.referralSource,
        referralNotes: referralNotes ? referralNotes : user.referralNotes,
      },
      intake: {
        id: data.intake.id ? data.intake.id : intake.id,
        isPhysioOnly: false,
        emergencyContact: emergencyContact ? emergencyContact : intake.emergencyContact,
        emergencyContactTel: emergencyContactTel ? emergencyContactTel : intake.emergencyContactTel,
        emergencyContactRel: emergencyContactRel ? emergencyContactRel : intake.emergencyContactRel,
        familyDoctor: familyDoctor ? familyDoctor : intake.familyDoctor,
        familyDoctorTel: familyDoctorTel ? familyDoctorTel : intake.familyDoctorTel,
        familyDoctorAddress: familyDoctorAddress ? familyDoctorAddress : intake.familyDoctorAddress,
        occupation: occupation ? occupation : intake.occupation,
        insurance: insurance ? insurance : intake.insurance,
        massageFrequency: massageFrequency ? massageFrequency : intake.massageFrequency,
        userType: userType ? userType : intake.userType,
        hasMedication: hasMedication ? hasMedication : intake.hasMedication,
        medication: medication ? medication : intake.medication,
        hasChronicPain: hasChronicPain ? hasChronicPain : intake.hasChronicPain,
        chronicPain: chronicPain ? chronicPain : intake.chronicPain,
        hasSurgeryOrInjury: hasSurgeryOrInjury ? hasSurgeryOrInjury : intake.hasSurgeryOrInjury,
        surgeryOrInjury: surgeryOrInjury ? surgeryOrInjury : intake.surgeryOrInjury,
        conditionNotes: conditionNotes ? conditionNotes : intake.conditionNotes,
        hadMassageBefore: hadMassageBefore ? hadMassageBefore : intake.hadMassageBefore,
        lastMassage: lastMassage ? lastMassage : intake.lastMassage,
        massageType: massageType ? massageType : intake.massageType,
        massagePressure: massagePressure ? massagePressure : intake.massagePressure,
        otherTreatments: otherTreatments ? otherTreatments : intake.otherTreatments,
        hasAllergies: hasAllergies ? hasAllergies : intake.hasAllergies,
        allergies: allergies ? allergies : intake.allergies,
        hasNoMassageArea: hasNoMassageArea ? hasNoMassageArea : intake.hasNoMassageArea,
        noMassageArea: noMassageArea ? noMassageArea : intake.noMassageArea,
        hasTreatmentGoal: hasTreatmentGoal ? hasTreatmentGoal : intake.hasTreatmentGoal,
        treatmentGoal: treatmentGoal ? treatmentGoal : intake.treatmentGoal,
        extraNotes: extraNotes ? extraNotes : intake.extraNotes,
      
        // busy mama
        birthDate: birthDate ? birthDate : intake.birthDate,
        deliveryMethod: deliveryMethod ? deliveryMethod : intake.deliveryMethod,
        childrenNumber: childrenNumber ? childrenNumber : intake.childrenNumber,
        hasBirthComplications: hasBirthComplications ? hasBirthComplications : intake.hasBirthComplications,
        birthComplications: birthComplications ? birthComplications : intake.birthComplications,
        hasPostpartumDepression: hasPostpartumDepression ? hasPostpartumDepression : intake.hasPostpartumDepression,
        postpartumDepression: postpartumDepression ? postpartumDepression : intake.postpartumDepression,
      
        // prenatal 
        pregnancyNumber: pregnancyNumber ? pregnancyNumber : intake.pregnancyNumber,
        birthNumber: birthNumber ? birthNumber : intake.birthNumber,
        trimester: trimester ? trimester : intake.trimester,
        dueDate: dueDate ? dueDate : intake.dueDate,
        pregnancyRisk: pregnancyRisk ? pregnancyRisk : intake.pregnancyRisk,
        hasMidwife: hasMidwife ? hasMidwife : intake.hasMidwife,
        midwife: midwife ? midwife : intake.midwife,
        midwifeTel: midwifeTel ? midwifeTel : intake.midwifeTel,
        prenatalConditionNotes: prenatalConditionNotes ? prenatalConditionNotes : intake.prenatalConditionNotes,
      
        // baby 
        weeksGestation: weeksGestation ? weeksGestation : intake.weeksGestation,
        hasPostpartumComplication: hasPostpartumComplication ? hasPostpartumComplication : intake.hasPostpartumComplication,
        postpartumComplication: postpartumComplication ? postpartumComplication : intake.postpartumComplication,
      },
      address: {
        id: data.intake.user.address.id ? data.intake.user.address.id : address.id,
        unit: unit ? unit : address.unit,
        buzzer: buzzer ? buzzer : address.buzzer,
        street: street ? street : address.street,
        city: city ? city : address.city,
        province: province ? province : address.province,
        country: country ? country : address.country,
        postalCode: postalCode ? postalCode : address.postalCode,
        homeType: homeType ? homeType : address.homeType,
        hasStairs: hasStairs ? hasStairs : address.hasStairs,
        stairsNotes: stairsNotes ? stairsNotes : address.stairsNotes,
        parkingType: parkingType ? parkingType : address.parkingType,
        parkingNotes: parkingNotes ? parkingNotes : address.parkingNotes,
      },
      musculoSkeletal: updateOtherHealth(musculoSkeletal, data.intake.musculoSkeletal),
      nervousSystem: updateOtherHealth(nervousSystem, data.intake.nervousSystem),
      digestive: updateOtherHealth(digestive, data.intake.digestive),
      otherConditions: updateOtherHealth(otherConditions, data.intake.otherConditions),
      respiratory: updateOtherHealth(respiratory, data.intake.respiratory),
      skin: updateOtherHealth(skin, data.intake.skin),
      prenatal: updateOtherHealth(prenatal, data.intake.existingPrenatalConditions),
      infant: updateOtherHealth(infant, data.intake.infantConditions),
    }

    initialLoad(state);
    history.push('/step-1');
  };

  const { loading, error, data } = useQuery(GET_INTAKE, {
    variables: {
      id,
    },
  });

  console.log('address', address);

  if (loading) { return <Loading /> };

  return (
    <div className='page' id='landing-page'>
      <div className='panel-body'>
        <h4>You are filling out an intake form for</h4>
        <h3>{`${data.intake.user.firstName} ${data.intake.user.lastName}`}</h3>
        <p>{`If you need to fill out an intake form for someone other than ${data.intake.user.firstName}, such as a family member or child, please contact us.`}</p>
        <div className='margin-top-20'>
          <Button color="secondary" variant="contained" onClick={handleNext}>
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  address: state.address,
  intake: state.intake,
  musculoSkeletal: state.musculoSkeletal,
  nervousSystem: state.nervousSystem,
  digestive: state.digestive,
  otherConditions: state.otherConditions,
  respiratory: state.respiratory,
  skin: state.skin,
  prenatal: state.prenatal,
  infant: state.infant,
});

const mapDispatchToProps = dispatch => ({
  initialLoad: (state) => {
    dispatch(initialLoad(state))
  },
});

export default connect (
  mapStateToProps,
  mapDispatchToProps,
)(LandingPage);
import React from 'react';
import { connect } from 'react-redux';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { changeMusculoSkeletal } from '../../actions/changeHealthInfo';

const MUSCULOSKELETAL = [
  'headaches', 'joint stiffness/swelling', 'spasms/cramps', 'broken/fractured bones',
  'strains/sprains', 'back, hip pain', 'shoulder, neck, arm, hand pain', 'leg, foot pain',
  'chest, ribs, abdominal pain', 'problems walking', 'jaw pain/TMJ', 'tendonitis', 'bursitis',
  'arthritis', 'osteoporosis', 'scoliosis'
];

const MusculoSkeletalSelector = ({ changeHealthInfo, state }) => {
  let options1;
  let options2;

  if (MUSCULOSKELETAL.length % 2 === 0) {
    options1 = MUSCULOSKELETAL.slice(0, MUSCULOSKELETAL.length/2);
    options2 = MUSCULOSKELETAL.slice(MUSCULOSKELETAL.length/2);
  } else {
    options1 = MUSCULOSKELETAL.slice(0, MUSCULOSKELETAL.length/2 + 1);
    options2 = MUSCULOSKELETAL.slice(MUSCULOSKELETAL.length/2 + 1);
  }

  return (
    <div className='checkbox-wrapper'>
      <FormControl component="fieldset">
        <FormLabel component="legend">Check the following "musculo-skeletal" conditions that apply to you, past and present:</FormLabel>
        <FormGroup>
          <div className='checkbox-set-wrapper'>
            <div className='checkbox-set'>
              {options1.map(ref => (
                 <FormControlLabel
                  key={ref}
                  control={<Checkbox checked={state[ref]} onChange={(e) => changeHealthInfo(ref)} value={ref} />}
                  label={ref}
                />
              ))}
            </div>
            <div className='checkbox-set'>
              {options2.map(ref => (
                <FormControlLabel
                  key={ref}
                  control={<Checkbox checked={state[ref]} onChange={(e) => changeHealthInfo(ref)} value={ref} />}
                  label={ref}
                />
              ))}
            </div>
          </div>
        </FormGroup>
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => ({
  state: state.musculoSkeletal,
});

const mapDispatchToProps = dispatch => ({
  changeHealthInfo: key => {
    dispatch(changeMusculoSkeletal(key))
  }
});

export default connect (
  mapStateToProps,
  mapDispatchToProps
)(MusculoSkeletalSelector);
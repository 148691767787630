const DIGESTIVE = [
  'indigestion', 'constipation', 'intestinal gas/bloating', 'diarrhea', 'irritable bowel syndrome',
  `crohn's disease`, 'colitis'
];

const initialState = {}; 
DIGESTIVE.map(item => initialState[item] = false);

const digestive = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.digestive;
    case 'CHANGE_DIGESTIVE':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default digestive;
const MUSCULOSKELETAL = [
  'headaches', 'joint stiffness/swelling', 'spasms/cramps', 'broken/fractured bones',
  'strains/sprains', 'back, hip pain', 'shoulder, neck, arm, hand pain', 'leg, foot pain',
  'chest, ribs, abdominal pain', 'problems walking', 'jaw pain/TMJ', 'tendonitis', 'bursitis',
  'arthritis', 'osteoporosis', 'scoliosis'
];

const initialState = {}; 
MUSCULOSKELETAL.map(item => initialState[item] = false);

const musculoSkeletal = (state = initialState, action) => {
  switch (action.type) {
    case 'INITIAL_LOAD':
      return action.musculoSkeletal;
    case 'CHANGE_MUSCULOSKELETAL':
      return {
        ...state,
        [action.key]: !state[action.key],
      }
    default:
      return state;
  }
}

export default musculoSkeletal;